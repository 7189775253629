import { graphql, Link } from 'gatsby';
import React from 'react';
import PropTypes, { shape } from 'prop-types';
import cx from 'classnames';

import Hero from '../../components/blocks/hero';
import Footer from '../../components/common/footer';
import SEO from '../../components/common/seo';
import Layout from '../../components/layout';
import constants from '../../utils/constants';
import styles from './ppp-loan.module.sass';

const PppLoan = ({ data: { ultimateGuide } }) => {
  return (
    <Layout footer={false}>
      <SEO
        title="Paycheck Protection Program (PPP) loans"
        description="K Servicing will be offering Kabbage’s existing PPP customers access
        to PPP loans as soon as they are available. Check back soon for more
        information."
        urlPath="/ppp-loan/"
      />
      <Hero imageClassName={styles.heroImage}>
        <h1 className={styles.heroHeading}>
          Paycheck Protection Program (PPP) loans<sup>*</sup>
        </h1>
        <p>
          At this time, KServicing is not accepting new second draw loan
          applications for borrowers that took their first PPP loan through
          Kabbage.
        </p>
        {/* <a
          className={cx('pill-button', styles.heroDashboardLink)}
          href={`https://${constants.domains.account}/login?next=%2Ffunding%2F%3F`}
          target="_blank"
          rel="noreferrer"
        >
          KServicing Dashboard
        </a> */}
        <p className={styles.heroDisclaimer}>
          Eligible businesses must have fewer than 300 employees and have seen a{' '}
          <Link to={ultimateGuide.frontmatter.urlPath}>
            25% reduction in revenues
          </Link>{' '}
          since their first PPP loan.
        </p>
      </Hero>
      <Footer>
        <li className="no-decimal">
          At this time, K Servicing is offering Paycheck Protection Program
          (&quot;Program&quot;) loans directly as an approved U.S. Small
          Business Administration (&quot;SBA&quot;) lender and indirectly on
          behalf of one or more other approved SBA lenders. Loan agreements will
          identify the lender to small businesses at signing, and any loan made
          under the Program must also be submitted to and approved by the SBA.
          Program funds are limited. K Servicing does not guarantee that
          applications will be processed and submitted before Program funding is
          no longer available. There is no cost to you to apply for a Program
          loan. Applying with K Servicing does not limit you from applying with
          other lenders, but you may only accept and sign one Program loan
          agreement.
        </li>
      </Footer>
    </Layout>
  );
};

export const query = graphql`
  query {
    ultimateGuide: markdownRemark(
      frontmatter: { id: { eq: "c4c249b9-73c8-5352-bbec-0da99f7b6d3a" } }
    ) {
      frontmatter {
        urlPath
      }
    }
  }
`;

PppLoan.propTypes = {
  data: shape({
    ultimateGuide: shape({
      frontmatter: shape({ urlPath: PropTypes.string }),
    }),
  }).isRequired,
};

export default PppLoan;
